<template>
	<div class="request-card">
		<router-link v-if="routeName" class="request-link" :to="{ name: routeName, params: { id: id } }"></router-link>
		<div class="request-card-start">
			<div class="request-card-top">
				<span class="eyebrow">{{ category }}</span>
				<div class="time">
					<span class="time-number" :class="{'grey': sent, 'cyan': complete}">{{ time }}</span>
					<!-- unread -->
					<ChatIcon v-if="unread" style="width: 15px;" class="chat-icon"></ChatIcon>
					<!-- sent -->
					<ReplyIcon v-if="sent" style="width: 15px;" class="reply-icon"></ReplyIcon>
					<!-- complete -->
					<TickIcon v-if="complete" style="width: 15px;" class="tick-icon"></TickIcon>
				</div>
			</div>
			<div class="request-card-content">
				<h3>{{ title }}</h3>
				<p>{{ content }}</p>
			</div>
		</div>
		<div class="request-card-end">
			<div class="request-card-bottom">
				<div class="location-wrap">
					<AnchorIcon style="width: 12px;" class="anchor-icon"></AnchorIcon>
					<span class="location">{{ location }}</span>
				</div>
				<span v-if="messageCount" class="message-count">{{ messageCount }}</span>
			</div>
		</div>
	</div>
</template>

<script>
import ChatIcon from '@/assets/chat-bubble.svg';
import ReplyIcon from '@/assets/reply.svg';
import TickIcon from '@/assets/tick.svg';
import AnchorIcon from '@/assets/anchor-black.svg';
export default {
	name: 'RequestCard',
	props: {
		title: String,
		category: String,
		content: String,
		location: String,
		messageCount: Number,
		time: String,
		unread: Boolean,
		complete: Boolean,
		sent: Boolean,
		routeName: String,
        id: Number
	},
	components: {
		ChatIcon,
		AnchorIcon,
		ReplyIcon,
		TickIcon
	}
};
</script>

<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.request-card {
		position: relative;
		text-align: left;
		box-shadow: 5px 15px 15px 5px $snow;
		padding: 17px 16px 17px;
		background: $white;
		border-radius: 12px;
		border: 1px solid $snow;
		min-height: 160px;
		display: flex;
	    flex-direction: column;
	    justify-content: space-between;
	    margin-bottom: 15px;
	    &:last-of-type {
	    	margin-bottom: 0;
	    }
	    .request-link {
	    	position: absolute;
	    	top: 0;
	    	left: 0;
	    	width: 100%;
	    	height: 100%;
	    	z-index: 5;
	    }
	    svg {
	    	overflow: visible;
	    	g, path {
	    		stroke-width: 4;
	    	}
	    	&.tick-icon {
	    		g, path {
		    		stroke: $cyan_base;
		    	}
	    	}
	    	&.chat-icon {
	    		g, path {
		    		stroke: $green_base;
		    	}
	    	}
	    	&.reply-icon {
	    		g, path {
		    		stroke: $grey;
		    	}
	    	}
	    	&.anchor-icon {
	    		g, path {
		    		stroke: $grey;
		    	}
	    	}
	    }
		.request-card-top {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 3px;
			.eyebrow {
				margin-bottom: 0;
			}
			.time {
				display: flex;
				align-items: center;
				.time-number {
					font-size: 14px;
					line-height: 17px;
					font-weight: $weight_600;
					color: $green_base;
					margin-right: 5px;
					&.grey {
						color: $grey;
					}
					&.cyan {
						color: $cyan_base;
					}
				}
			}
		}
		.request-card-content {
			h3 {
				font-size: 17px;
				line-height: 20px;
				color: $dark;
				margin-bottom: 5px;
			}
			p {
				font-size: 14px;
				letter-spacing: -0.41px;
				line-height: 19px;
				padding-bottom: 15px;
			}
		}
		.request-card-bottom {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.location-wrap {
				display: flex;
				align-items: center;
				.location {
					display: block;
					font-size: 14px;
					line-height: 17px;
					font-weight: $weight_600;
					color: $grey;
					margin-left: 5px;
				}
			}
		}
		.message-count {
			height: 17px;
			width: 17px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 100%;
			background: $green_base;
			color: $white;
			font-weight: $weight_600;
			font-size: 10px;
		}
	}
</style>
